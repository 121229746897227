<template>
  <div class='dashboard-editing-layout'>
    <h2 class='mb-2 text-lg font-semibold uppercase text-gray-800'>Layout</h2>
    <el-radio-group v-model='view_layout' size='small'>
      <el-radio-button label='Card'></el-radio-button>
      <el-radio-button label='List View'></el-radio-button>
      <el-radio-button label='List Edit'></el-radio-button>
      <el-radio-button label='Monthly'></el-radio-button>
      <el-radio-button label='Weekly'></el-radio-button>
    </el-radio-group>
    <dashboard-view-weekly-configurations v-if='showWeeklyConfigurations' />
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import DashboardViewWeeklyConfigurations from '@/views/dashboard/DashboardViewWeeklyConfigurations.vue'

export default {
  name: 'DashboardViewEditingLayout',
  components: {
    DashboardViewWeeklyConfigurations,
  },
  computed: {
    ...mapFields('dashboardViews', [
      'currentDashboardView.view_layout',
    ]),
    showWeeklyConfigurations () {
      return (this.view_layout) ? this.view_layout === 'Weekly' : false
    },
  },
}
</script>
